import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RootState } from "../store";

const baseUrl: string = `${process.env.REACT_APP_API_URL}/api/v1`;

type Params = {
  page?: number;
  size?: number;
  state?: PV_State;
};

const reportingApi = createApi({
  reducerPath: "reporting",
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).auth.user?.token;
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }

      return headers;
    },
  }),
  endpoints: (builder) => ({
    getReportingData: builder.query<ReportingData, void>({
      query: () => `/pvsystemsreports`,
    }),

    getPvSystemsTable: builder.query<PV_SystemsTable, Params>({
      query: ({ page, size, state }) =>
        `/pvsystems?page=${page}&size=${size}&${
          state !== undefined && `state=${state}`
        }`,
      // Only have one cache entry because the arg always maps to one string
      // @ts-ignore
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      // Always merge incoming data to the cache entry
      // @ts-ignore
      merge: (currentCache, newItems, { arg: { state, page } }) => {
        // if (state !== undefined) {
        //   console.log("currentCache => ", currentCache.data);
        //   currentCache.data = [...currentCache.data, ...newItems.data].filter(
        //     (v) => v.state === state
        //   );
        // } else {
        // }
        currentCache.data.push(...newItems.data);
      },
      // Refetch when the page arg changes
      // @ts-ignore
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
    }),

    getPvSystems: builder.query<PV_SystemsTableRow, string>({
      query: (id) => `/pvsystems/${id}`,
    }),

    getPvSystemOverview: builder.query<PV_SystemsTable, { min_view?: string }>({
      query: ({ min_view }) => ({
        url: "/pvsystems",
        params: {
          min_view,
          size: 40,
        },
      }),
    }),

    getPvSystemReporting: builder.query<Reporting, { id: string | number }>({
      query: ({ id }) => ({
        url: "/pvsystemreporting",
        params: {
          pv_system_id: id,
        },
      }),
    }),
  }),
});

export const {
  useGetReportingDataQuery,
  useGetPvSystemsTableQuery,
  useGetPvSystemsQuery,
  useGetPvSystemOverviewQuery,
  useGetPvSystemReportingQuery,
  useLazyGetPvSystemReportingQuery,
} = reportingApi;
export default reportingApi;
