import { Slide, useTheme } from "@mui/material";
import { Link, Outlet, useLocation } from "react-router-dom";
import { useAppSelector } from "../redux/hooks";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { useGetPvSystemOverviewQuery } from "../redux/service/reportingApi";
import ReactPlayer from "react-player/youtube";
import { useGetOveriewDataQuery } from "../redux/service/overviewApi";
import ApexChart from "./ApexChart";
import Footer from "./Footer";
import { useScreenWidth } from "../utils/helpers";

const labels = [
  "0:00",
  "01:00",
  "02:00",
  "03:00",
  "04:00",
  "05:00",
  "06:00",
  "07:00",
  "08:00",
  "09:00",
  "10:00",
  "11:00",
  "12:00",
  "13:00",
  "14:00",
  "15:00",
  "16:00",
  "17:00",
  "18:00",
  "19:00",
  "20:00",
  "21:00",
  "22:00",
  "23:00",
];

function Layout() {
  const location = useLocation();
  const { sidebarState } = useAppSelector((state) => state.sidebar);

  // * RTK Query
  const { data } = useGetPvSystemOverviewQuery({ min_view: "true" });
  const { data: overviewData } = useGetOveriewDataQuery();

  const { width } = useScreenWidth();

  return (
    <div>
      <Header />
      <div className="flex">
        {location.pathname === "/" && (
          <Slide in={sidebarState} direction="right" unmountOnExit mountOnEnter>
            <Sidebar />
          </Slide>
        )}
        <div
          className={`flex-1  bg-[#F7F9F5]  ${
            location.pathname === "/" && ""
          } `}
        >
          <Outlet />
          {location.pathname !== "/login" &&
            location.pathname !== "/" &&
            location.pathname !== "/dashboard" && <Footer />}
        </div>
        {location.pathname === "/" && (
          <Slide in={sidebarState} direction="left" mountOnEnter>
            <div
              className={`flex-1 flex flex-col justify-between max-w-[360px] h-[calc(100vh-70px)] top-[70px] z-40 pt-5 overflow-y-scroll scrollbar-hide px-4 bg-gray-50 fixed right-0 w-full`}
            >
              <div className="">
                <h2 className="flex gap-5 mx-4 my-5 items-center">
                  <img
                    src="/images/overview-Icons/Overview-pvsystem-Icon.png"
                    alt=""
                  />
                  {/* <img src="/images/overview-Icons/Group 386.svg" alt="" /> */}
                  <span className="text-lg font-medium uppercase">
                    PV Systems
                  </span>
                </h2>
                <div className="space-y-2 flex-1 max-h-[35vh] 3xl:max-h-[45vh] overflow-y-auto scrollbar-hide ">
                  {data?.data?.map((item, index) => (
                    <div key={item.id} className="">
                      <Link
                        to={`/pvsystem/${item.id}`}
                        className=" cursor-pointer"
                      >
                        <div className="flex items-center justify-between bg-white p-2 !py-3 shadow-sm rounded-md">
                          <img
                            className="object-contain h-10 bg-white w-14 border p-[0.5px] "
                            src={item.posterUrl}
                            alt=""
                          />
                          <span className="text-[13px] font-medium">
                            {item.name}
                          </span>
                          <div className="corner__borders__2 px-2 py-[0px] ">
                            <span className="text-xs font-semibold text-[#74af4d] ">
                              {Number(Number(item.pdc) / 1000).toFixed(2) ??
                                "null"}{" "}
                              kW
                            </span>
                          </div>
                        </div>
                      </Link>
                    </div>
                  ))}
                </div>
              </div>
              <div className="">
                {/* Realtime stream */}
                <div className="">
                  <h2 className="flex gap-5 mx-4 my-5 items-center  ">
                    <img src="/images/overview-Icons/Icon 3.svg" alt="" />

                    <span className="text-lg font-medium uppercase">
                      Realtime stream{" "}
                    </span>
                  </h2>
                  <ReactPlayer
                    height={160}
                    width={310}
                    url={overviewData?.url_stream}
                    controls
                    muted
                    playing={true}
                    loop
                  />
                </div>
                {/* Realtime production */}
                <div className="pb-10">
                  <h2 className="flex gap-5 mx-4 my-5 items-center  ">
                    <img
                      src="/images/overview-Icons/realtimePvIcon.svg"
                      alt=""
                    />
                    <span className="text-lg font-medium uppercase">
                      Realtime production
                    </span>
                  </h2>
                  <ApexChart
                    NoToolbar
                    height={150}
                    series={[
                      // @ts-ignore
                      {
                        data: overviewData?.pv_production.values,
                        name: "value",
                      },
                    ]}
                    type="area"
                    options={{
                      colors: ["#74af4d"],
                      labels: overviewData?.pv_production.labels,
                      title: {
                        align: "left",
                        text:
                          overviewData?.pv_production.values[
                            Number(overviewData?.pv_production.values.length) -
                              1
                          ] + " kW",
                        offsetY: 0,
                        // offsetX: -80,
                        style: {
                          fontWeight: 600,
                          fontSize: "18px",
                        },
                      },
                      chart: {
                        id: "revenue",
                        toolbar: {
                          show: false,
                        },
                        sparkline: {
                          enabled: true,
                        },
                      },
                      grid: {
                        show: false,
                      },
                      // colors: ["warning"],
                      dataLabels: {
                        enabled: false,
                      },
                      stroke: {
                        curve: "smooth",
                        width: 2.5,
                      },
                      fill: {
                        type: "gradient",
                        gradient: {
                          shadeIntensity: 0.9,
                          opacityFrom: 0.7,
                          opacityTo: 0.5,
                          stops: [0, 80, 100],
                        },
                      },

                      xaxis: {
                        labels: {
                          show: true,
                        },
                        axisBorder: {
                          show: true,
                        },
                      },
                      yaxis: {
                        labels: {
                          show: false,
                        },
                      },
                      tooltip: {
                        // x: { show: false },
                      },
                    }}
                  />
                </div>
              </div>
            </div>
          </Slide>
        )}
      </div>
    </div>
  );
}

export default Layout;
