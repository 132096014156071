import {
  Fragment,
  useEffect,
  useState,
  useCallback,
  useLayoutEffect,
} from "react";
import Modal from "../components/Modal";
import ImageMapper, { MapAreas } from "react-img-mapper";
import { data as mapDataOverview } from "../data";
import { MinusIcon, PlusIcon } from "@heroicons/react/outline";
import Polygon from "../components/Polygon";
import { useLocation, useNavigate } from "react-router-dom";
import { useGetPvSystemOverviewQuery } from "../redux/service/reportingApi";

function Home() {
  const [isOpen, setIsOpen] = useState(false);
  const [jsx, setJsx] = useState<JSX.Element>(<></>);
  const [zoom, setZoom] = useState(0.2);
  const [showButtons, setShowButtons] = useState(false);
  const [showPolygons, setShowPolygons] = useState(false);
  const [width, setWidth] = useState<number>(window.innerWidth);
  const [panelId, setPanelId] = useState<string | null>(null);

  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const id = setTimeout(() => {
      setShowPolygons(true);
    }, 2000);

    return () => clearTimeout(id);
  }, [pathname]);

  // * update current width
  useEffect(() => {
    const handler = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener("resize", handler);

    return () => window.removeEventListener("resize", handler);
  }, []);

  useLayoutEffect(() => {
    if (width > 1536) {
      setZoom(0.25);
    }

    if (width >= 1920) {
      setZoom(0.3);
    }

    if (width >= 2560) {
      setZoom(0.35);
    }

    if (width <= 1024) {
      setZoom(1);
    }
  }, [width]);

  // * State handlers
  const zoomIn = useCallback(() => {
    setZoom((p) => p + 0.1);
  }, []);

  const zoomOut = useCallback(() => {
    setZoom((p) => p - 0.1);
  }, []);

  const handleShowButtons = useCallback(() => {
    setShowButtons((p) => !p);
  }, []);

  function renderPoint(point: number[], i: number, is: boolean) {
    return (
      <polyline
        // style={{
        //   pointerEvents: "none",
        // }}
        className={`stroke-[15]  ${
          is
            ? "fill-green-400 opacity-70 stroke-[15] stroke-green-400"
            : "fill-transparent"
        } `}
        stroke="10px"
        // @ts-ignore
        points={point}
        x={point[0]}
        y={point[1]}
      />
    );
  }
  const { data } = useGetPvSystemOverviewQuery({ min_view: "true" });

  // * Array de display polygons
  const displayPolygons = data?.data
    .map((item) => ({
      startAt: item.start_position,
      endAt: item.end_position,
      id: item.id,
      name: item.name,
      power: item.power,
      inverter_power: item.inverter_power,
      modules_nb: item.modules_nb ?? "null",
    }))
    .filter((item) => item.startAt && item.endAt);

  // * When you click the pannel
  function clicked(area: any) {
    setIsOpen(true);
    for (const poly of displayPolygons ?? []) {
      if (
        area.name &&
        area.name >= Number(poly.startAt) &&
        area.name <= Number(poly.endAt)
      ) {
        setPanelId(poly.id);
        return setJsx(
          <div className="flex gap-3">
            <div>
              <div>id </div>
              <div>Name </div>
              <div>Power </div>
              <div>Inverter Power </div>
              <div>Number of modules </div>
            </div>
            <div>
              <div>: {poly.id} </div>
              <div onClick={() => navigate(`/pvsystem/${poly.id}`)}>
                :{" "}
                <span className="hover:underline cursor-pointer">
                  {poly.name}
                </span>{" "}
              </div>
              <div>: {poly.power} kWp</div>
              <div>: {poly.inverter_power} kVA</div>
              <div>: {poly.modules_nb} </div>
            </div>
          </div>
        );
        // for (const item of mapDataOverview) {
        //   console.log(item.name);
        //   console.log(area.name);
        //   console.log(area?.name === item.name);
        //   if (area.name) {
        //   }
        // }
      }
    }

    return setJsx(<>This pannel identifiant is : {area.name} </>);
  }
  // @ts-ignore
  // var isFirefox = InstallTrigger !== "undefined";
  console.log("InstallTrigger =>", "InstallTrigger" in window);
  return (
    <div>
      <div
        id="image-container"
        style={{
          zoom,
          ...("InstallTrigger" in window && {
            transform: `scale(${zoom})`,
            transformOrigin: "0 0",
          }),
        }}
        className="relative mt-44"
      >
        {displayPolygons && (
          <ImageMapper
            src={"/images/interactive.png"}
            width={7740}
            height={4330}
            map={{ name: "my-map", areas: mapDataOverview }}
            onClick={clicked}
          />
        )}

        {/* TODO: draw polygon over elements based on startAt & endAt inside displayPolygons */}
        <svg
          width="7740"
          height="4330"
          className={
            "absolute top-0 left-0 pointer-events-none !h-[4330px] right-0 !bottom-0 z-10 "
          }
        >
          {showPolygons &&
            Array.from(Array((displayPolygons ?? []).length))
              .fill(displayPolygons ?? {})
              .map((v: Array<{ startAt: number; endAt: number }>, i) => {
                return (
                  <Polygon
                    key={i}
                    className=""
                    fill=""
                    // @ts-ignore
                    onClick={() => {
                      clicked({ name: v[i].startAt });
                      console.log("Polygon clicked");
                    }}
                    array={mapDataOverview.slice(v[i].startAt - 1, v[i].endAt)}
                    renderPoint={renderPoint}
                  />
                );
              })}
        </svg>
      </div>
      <Modal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        panelId={panelId}
        render={() => <>{jsx}</>}
      />
      <div className="fixed flex flex-col items-center justify-center gap-3 z-40 bottom-10 right-10">
        {showButtons && (
          <>
            <button
              type="button"
              onClick={zoomIn}
              className="text-gray-800 bg-gray-100 hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm p-2.5 text-center inline-flex items-center"
            >
              <PlusIcon className="h-6" />
              <span className="sr-only">Icon description</span>
            </button>
            <button
              type="button"
              onClick={zoomOut}
              // disabled={
              //   (width >= 1536 && zoom <= 0.25) ||
              //   (width >= 1920 && zoom <= 0.3)
              // }
              className="text-gray-800 bg-gray-100 hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm p-2.5 text-center inline-flex items-center"
            >
              <MinusIcon className="h-6" />
              <span className="sr-only">Icon description</span>
            </button>
          </>
        )}
        <button
          onClick={handleShowButtons}
          className="bg-gray-100  rounded-xl mt-2 px-3 py-3"
        >
          <img className="h-5" src="/images/Vector.svg" alt="" />
        </button>
      </div>
    </div>
  );
}

export default Home;
