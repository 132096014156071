import { useCallback, useEffect } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import Layout from "./components/Layout";
import PVsystem from "./pages/PVsystem";
import Alarms from "./pages/Alarms";
import Home from "./pages/Home";
import Login from "./pages/Login";
import PVsystems from "./pages/PVsystems";
import Dashboard from "./pages/Dashboard";
import WeatherStation from "./pages/WeatherStation";
import Welcome from "./pages/Welcome";
import { useAppDispatch, useAppSelector } from "./redux/hooks";
import jwt_decode, { JwtPayload } from "jwt-decode";
import PrivateRoute from "./components/PrivateRoute";
import { API_URL } from "./utils/helpers";
import { updateToken } from "./redux/slices/auth";
import Header from "./components/Header";
import PdfViewer from "./pages/PdfViewer";

interface NewToken {
  token: string;
}

function App() {
  const { user } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  // * get new token
  const getNewToken = useCallback(async () => {
    const response = await fetch(`${API_URL}/api/v1/refresh`, {
      method: "POST",
      body: JSON.stringify({ token: user?.token }),
      headers: {
        "Content-Type": "application/json",
      },
    });

    const data = await response.json();
    console.log(data);
    data && dispatch(updateToken(data.token));
    // return data;
  }, [user?.token]);

  // * Check if jwt token is valid
  useEffect(() => {
    if (user?.token) {
      const decoded = jwt_decode<JwtPayload>(user?.token as string);

      if (decoded?.exp! * 1000 < Date.now()) {
        getNewToken();
        // navigate("/login");
      }
    }
  }, [user?.token, navigate]);
  return (
    <div className="min-h-screen">
      <Routes>
        <Route path="/login" element={<Login />} />
        {/* Private Routes */}
        <Route path="" element={<PrivateRoute />}>
          <Route path="/welcome" element={<Welcome />} />
          <Route path="/" element={<Layout />}>
            <Route path="" element={<Home />} />
            <Route path="pvsystems" element={<PVsystems />} />
            <Route path="weatherstation" element={<WeatherStation />} />
            <Route path="alarms" element={<Alarms />} />
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="pvsystem/:id" element={<PVsystem />} />
          </Route>
        </Route>
        {/* @ts-ignore */}
        <Route path="pdf-generate" element={<PdfViewer />} />
      </Routes>
    </div>
  );
}

export default App;
