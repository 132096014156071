import ProgressBar from "@ramonak/react-progress-bar";
import { ReactNode, useEffect, useLayoutEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import Table from "../components/Table";
import {
  useGetPvSystemReportingQuery,
  useGetPvSystemsQuery,
} from "../redux/service/reportingApi";
import { checkNumber, checker } from "../utils/helpers";

const arr = Array.from(new Array(10)).map((v) =>
  Array.from(new Array(15)).map(() => "/images/panneau.png")
);

function PVsystem() {
  const { id } = useParams();
  const {
    data: pvSystemData,
    isLoading,
    isFetching,
  } = useGetPvSystemsQuery(id as string);

  const sideRight = useRef<HTMLDivElement>(null);
  const leftElement = useRef<HTMLDivElement>(null);

  const [height, setHeight] = useState<number | null>(null);
  useLayoutEffect(() => {
    setHeight(leftElement.current?.getBoundingClientRect().height ?? 0);
  });

  let acPowerMaxValue =
    (Number(pvSystemData?.inverter?.vmax_ac) *
      Number(pvSystemData?.inverter?.imax_ac)) /
    1000;

  // * a
  const ac_power_a =
    (Number(pvSystemData?.ac_system?.voltage_phase?.l1 as string) *
      Number(pvSystemData?.ac_system?.current_phase.a as string)) /
    1000;

  // * b
  const ac_power_b =
    (Number(pvSystemData?.ac_system?.voltage_phase?.l2 as string) *
      Number(pvSystemData?.ac_system?.current_phase.b as string)) /
    1000;

  // * c
  const ac_power_c =
    (Number(pvSystemData?.ac_system?.voltage_phase?.l3 as string) *
      Number(pvSystemData?.ac_system?.current_phase.c as string)) /
    1000;

  return (
    <div className=" flex gap-4 p-5 items-start">
      <div ref={leftElement} className="space-y-4 flex-1">
        {/* ------- Trina system ------- */}
        <div className="bg-white p-6 rounded-lg border shadow-md">
          <div className="flex justify-between mb-6 items-center">
            <h2 className=" text-2xl flex items-center gap-2 !text-[#333] font-semibold ">
              {pvSystemData?.name ?? "null"}{" "}
              <span
                style={{
                  background:
                    pvSystemData?.stateColor === "red"
                      ? "#ffe8e7"
                      : pvSystemData?.stateColor === "orange"
                      ? "#faf1d7"
                      : pvSystemData?.stateColor === "green"
                      ? "#ddf7cd"
                      : pvSystemData?.stateColor === "blue"
                      ? "#dcedfc"
                      : pvSystemData?.stateColor === "yellow"
                      ? "#f7fcd7"
                      : "light" + pvSystemData?.stateColor,
                  color:
                    pvSystemData?.stateColor === "green"
                      ? "#40c702"
                      : pvSystemData?.stateColor === "red"
                      ? "#c70202"
                      : pvSystemData?.stateColor === "blue"
                      ? "#026bc7"
                      : pvSystemData?.stateColor === "yellow"
                      ? "#F6BE00"
                      : pvSystemData?.stateColor === "orange"
                      ? "#e3a702"
                      : "dark" + pvSystemData?.stateColor,
                }}
                className={`capitalize text-center whitespace-nowrap px-3 py-1 text-xs font-medium rounded-full `}
              >
                {pvSystemData?.stateAlias ?? "null"}
              </span>
            </h2>
            <img
              className="h-[32px] object-contain "
              src="/images/pvsystem-Icons/TrinaSystemIcon.svg"
              alt=""
            />
          </div>
          <div className="flex justify-around">
            <TrinaSystemCard
              title="Structure"
              render={() => (
                <>
                  <li className="flex gap-3 ">
                    <div className="space-y-4">
                      <p className="flex items-center gap-3">
                        {" "}
                        <div className="h-1 w-1 self-center bg-gray-500 rounded-full " />
                        <span>Installed Power</span>
                      </p>
                      <p className="flex items-center gap-3">
                        {" "}
                        <div className="h-1 w-1 self-center bg-gray-500 rounded-full " />
                        <span>Number of Arrays</span>
                      </p>
                      <p className="flex items-center gap-3">
                        {" "}
                        <div className="h-1 w-1 self-center bg-gray-500 rounded-full " />
                        <span>Number of Modules</span>
                      </p>
                      <p className="flex items-center gap-3">
                        {" "}
                        <div className="h-1 w-1 self-center bg-gray-500 rounded-full " />
                        <span>Tilt angle </span>
                      </p>
                      <p className="flex items-center gap-3">
                        {" "}
                        <div className="h-1 w-1 self-center bg-gray-500 rounded-full " />
                        <span>Orientation</span>
                      </p>
                    </div>
                    <div className="flex flex-col space-y-4">
                      <span>:</span>
                      <span>:</span>
                      <span>:</span>
                      <span>:</span>
                      <span>:</span>
                    </div>
                    <div className="space-y-4">
                      <p> {pvSystemData?.power ?? "null"} kWp</p>
                      <p> {pvSystemData?.strings_nb ?? "null"}</p>
                      <p> {pvSystemData?.mod_nb ?? "null"}</p>
                      <p> {"31°"}</p>
                      <p> {"South"}</p>
                    </div>
                  </li>
                </>
              )}
            />
            <TrinaSystemCard
              title="Module Characteristics"
              render={() => (
                <>
                  <li className="flex  gap-3  ">
                    <div className="space-y-4">
                      <p className="flex items-center gap-3">
                        {" "}
                        <div className="h-1 w-1 self-center bg-gray-500 rounded-full " />
                        <span>Technology</span>
                      </p>
                      <p className="flex items-center gap-3">
                        {" "}
                        <div className="h-1 w-1 self-center bg-gray-500 rounded-full " />
                        <span>Current Isc</span>
                      </p>
                      <p className="flex items-center gap-3">
                        {" "}
                        <div className="h-1 w-1 self-center bg-gray-500 rounded-full " />
                        <span>Current Impp</span>
                      </p>
                      <p className="flex items-center gap-3">
                        {" "}
                        <div className="h-1 w-1 self-center bg-gray-500 rounded-full " />
                        <span>Voltage Voc</span>
                      </p>
                      <p className="flex items-center gap-3">
                        {" "}
                        <div className="h-1 w-1 self-center bg-gray-500 rounded-full " />
                        <span>Voltage Vmp</span>
                      </p>
                    </div>
                    <div className="flex flex-col space-y-4">
                      <span>:</span>
                      <span>:</span>
                      <span>:</span>
                      <span>:</span>
                      <span>:</span>
                    </div>
                    <div className="space-y-4">
                      <p>
                        {pvSystemData?.module_type.name ?? "null"} -{" "}
                        {Number(pvSystemData?.module_type.power)?.toFixed(2)} Wc{" "}
                      </p>
                      <p className="flex items-center gap-3">
                        {pvSystemData?.module_type.isc ?? "null"} A
                      </p>
                      <p className="flex items-center gap-3">
                        {pvSystemData?.module_type.imp ?? "null"} A
                      </p>
                      <p className="flex items-center gap-3">
                        {pvSystemData?.module_type.voc ?? "null"} V
                      </p>
                      <p className="flex items-center gap-3">
                        {pvSystemData?.module_type.vmp ?? "null"} V
                      </p>
                    </div>
                  </li>
                </>
              )}
            />
            <TrinaSystemCard
              title="Inverter Characteristics"
              render={() => (
                <>
                  <li className="flex  gap-3 ">
                    <div className="space-y-4">
                      <p className="flex items-center gap-3">
                        {" "}
                        <div className="h-1 w-1 self-center bg-gray-500 rounded-full " />
                        <span>Inventer Model</span>
                      </p>
                      <p className="flex items-center gap-3">
                        {" "}
                        <div className="h-1 w-1 self-center bg-gray-500 rounded-full " />
                        <span>Nominal Power</span>
                      </p>
                      <p className="flex items-center gap-3">
                        {" "}
                        <div className="h-1 w-1 self-center bg-gray-500 rounded-full " />
                        <span>Max Voltage</span>
                      </p>
                      <p className="flex items-center gap-3">
                        {" "}
                        <div className="h-1 w-1 self-center bg-gray-500 rounded-full " />
                        <span>Max Current</span>
                      </p>
                      <p className="flex items-center gap-3">
                        {" "}
                        <div className="h-1 w-1 self-center bg-gray-500 rounded-full " />
                        <span>Max Efficiency</span>
                      </p>
                    </div>
                    <div className="flex flex-col space-y-4">
                      <span>:</span>
                      <span>:</span>
                      <span>:</span>
                      <span>:</span>
                      <span>:</span>
                    </div>
                    <div className="space-y-4">
                      <p> {pvSystemData?.inverter?.type}</p>
                      <p> {pvSystemData?.inverter?.power} kVA</p>
                      <p> {pvSystemData?.inverter?.vmax} V</p>
                      <p> {pvSystemData?.inverter?.imax_ac} A</p>
                      <p> {pvSystemData?.inverter?.efficiency ?? 0}%</p>
                    </div>
                  </li>

                  {/* <li className="flex gap-3 "></li>
                  <li className="flex gap-3 "></li> */}
                </>
              )}
            />
          </div>
        </div>
        <div className="">
          <div className="flex items-center">
            <span
              style={{
                writingMode: "sideways-rl",
              }}
              className="text-center mt-32 -rotate-90 w-10 whitespace-nowrap text-xl text-gray-600"
            >
              Number of Arrays
            </span>
            {/* <div className="">
            </div> */}

            <div className="flex flex-1 flex-wrap gap-1 sm:max-w-[420px] mx-auto md:max-w-[780px] lg:max-w-[850px]  3xl:max-w-[1380px]  ">
              {arr.map((img, index) =>
                img.map((_, i) => (
                  <img
                    className={`sm:h-5 md:h-7 lg:h-[30px]  3xl:h-[50px] mx-auto object-contain ${
                      index < Number(pvSystemData?.strings_nb) &&
                      i < Number(pvSystemData?.mod_nb) &&
                      "!opacity-100"
                    } cursor-pointer opacity-50 `}
                    src={img[i]}
                    alt=""
                  />
                ))
              )}
            </div>
          </div>
          <p className="text-center text-xl text-gray-600 mt-2">
            Number of Modules
          </p>
        </div>
        {sideRight.current && (
          <Table
            title="Alarm Status"
            messageTop={"!top-[50%] "}
            className="shadow-md border self-stretch"
            theader={["ID", "Date time", "Priority", "Note"]}
            hideAdd
            hideSearch
            tbody={pvSystemData?.alarms ?? []}
            loading={isLoading || isFetching}
            render={(item) => (
              <>
                <th
                  scope="row"
                  className="px-4 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap"
                >
                  {item.id}
                </th>
                <th
                  scope="row"
                  className="px-4 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap"
                >
                  {(item.datetime as string) ?? "null"}
                </th>
                <th
                  scope="row"
                  className={`px-4 py-4 font-medium 
                  text-gray-900 dark:text-white whitespace-nowrap`}
                >
                  <p
                    style={{
                      background:
                        item.priorityColor === "red"
                          ? "#ffe8e7"
                          : item.priorityColor === "orange"
                          ? "#fcf2d7"
                          : item.priorityColor === "yellow"
                          ? "#f7fcd7"
                          : "light" + item.priorityColor,

                      color:
                        item.priorityColor === "yellow"
                          ? "#F6BE00"
                          : "dark" + item.priorityColor,
                    }}
                    className="capitalize text-center  border  px-4 py-2 rounded-full"
                  >
                    {item.priorityAlias}
                  </p>
                </th>
                <th
                  scope="row"
                  className="px-4 py-4 font-medium text-gray-900 dark:text-white"
                >
                  {item.note}
                </th>
              </>
            )}
          />
        )}
      </div>
      <div
        style={{
          height: height as number,
        }}
        ref={sideRight}
        className="bg-white p-5 space-y-7 border h-full scrollbar-hide  overflow-y-scroll  flex-1 flex flex-col gap-4 max-w-[200px] lg:max-w-xs rounded-lg shadow-md"
      >
        {/* --------- DC Power ---------- */}
        <div className="">
          <div className="flex justify-between mb-4 items-center">
            <h2 className="text-xl mb-4 font-semibold !text-[#333]">
              {" "}
              DC Power{" "}
            </h2>
            <img
              className="object-contain h-[32px] w-[32px]"
              src="/images/pvsystem-Icons/PowerIcon.svg"
              alt=""
            />
          </div>{" "}
          <p className="text-[#898989] font-semibold border-2 py-4  rounded-xl text-center border-green-500 text-xl">
            {" "}
            {checker(
              pvSystemData?.dc_system?.pdc
                ? parseInt(pvSystemData?.dc_system?.pdc as string) / 1000
                : null,
              "kW"
            )}
          </p>
        </div>

        {/* ----------- DC Voltage --------- */}
        <div className="">
          <div className="flex justify-between mb-4 items-center">
            <h2 className="text-xl font-semibold !text-[#333]"> DC Voltage </h2>
            <img
              className="object-contain h-[32px] w-[32px] "
              src="/images/pvsystem-Icons/VoltageIcon.svg"
              alt=""
            />
          </div>{" "}
          <p className="text-[#898989] font-semibold border-2 py-4  rounded-xl text-center border-green-500 text-xl">
            {" "}
            {/* @ts-ignore */}
            {checker(pvSystemData?.dc_system?.vdc, "V")}
          </p>
        </div>

        {/* --------- DC Currents ---------- */}
        <div className="">
          <div className="flex justify-between mb-4 items-center">
            <h2 className="text-xl font-semibold !text-[#333]">DC Currents </h2>
            <img
              className="object-contain h-[32px] w-[32px] "
              src="/images/pvsystem-Icons/CurrentIcon.svg"
              alt=""
            />
          </div>{" "}
          <div
            className={`scrollbar-hide space-y-2 border-y-2 ${
              !pvSystemData?.dc_system?.data_i && "hidden"
            }`}
          >
            {Object.entries(pvSystemData?.dc_system?.data_i ?? {})
              // TODO: ----------
              .filter(([key, value]) => value !== null && parseInt(value) !== 0)
              .map(([key, value]) => (
                <p className=" justify-between px-3 border-b last:border-0 py-2 ">
                  <span className=" mr-auto uppercase">
                    {key}: {Number(Number(value).toFixed(2))} A
                  </span>{" "}
                  {/* <span>{value}</span> */}
                  <ProgressBar
                    height="10px"
                    completed={value ?? 0}
                    customLabel={" "}
                    maxCompleted={Number(pvSystemData?.module_type?.isc ?? 0)}
                    animateOnRender
                    bgColor={"#74AF4D"}
                  />
                </p>
              ))}
          </div>
        </div>

        {/* ------- Voltage ------- */}
        <div className="">
          <div className="flex justify-between mb-4 items-center">
            <h2 className="text-xl font-semibold  !text-[#333]">
              {" "}
              AC Voltage{" "}
            </h2>
            <img
              className="object-contain h-[32px] w-[32px] "
              src="/images/pvsystem-Icons/VoltageIcon.svg"
              alt=""
            />
          </div>

          <div className="space-y-5">
            {Object.entries(pvSystemData?.ac_system?.voltage_phase ?? {}).map(
              ([key, value]) => (
                <p className="flex flex-col justify-between px-3 py-2 border-b">
                  <span className=" mr-2 uppercase  text-gray-600 tracking-widest	">
                    {key} : {Number(value).toFixed(2)} V
                  </span>
                  <ProgressBar
                    className="flex-1 space-x-2 mr-1"
                    height="10px"
                    completed={value}
                    customLabel={" "}
                    maxCompleted={Number(pvSystemData?.inverter?.vmax_ac)}
                    animateOnRender
                    bgColor={"#74AF4D"}
                  />
                </p>
              )
            )}
          </div>
        </div>
        {/* ------- Currents -------- */}
        <div className="">
          <div className="flex justify-between mb-4 items-center">
            <h2 className="text-xl font-semibold !text-[#333]">AC Currents </h2>
            <img
              className="object-contain h-[32px] w-[32px] "
              src="/images/pvsystem-Icons/CurrentIcon.svg"
              alt=""
            />
          </div>
          <div className="space-y-5">
            <p className={`flex flex-col justify-between px-3 py-2 border-b `}>
              {" "}
              <span className=" mr-2 text-gray-600 tracking-widest">
                I1 :{" "}
                {Number(
                  checkNumber(pvSystemData?.ac_system?.current_phase?.a)
                ).toFixed(2) ?? 0}{" "}
                A
              </span>
              <ProgressBar
                className="flex-1 space-x-2 mr-1"
                height="10px"
                completed={pvSystemData?.ac_system?.current_phase?.a ?? 0}
                maxCompleted={Number(pvSystemData?.inverter?.imax_ac ?? "100")}
                customLabel={" "}
                animateOnRender
                bgColor={"#74AF4D"}
              />
            </p>
            <p className={`flex flex-col justify-between px-3 py-2 border-b `}>
              {" "}
              <span className=" mr-2 text-gray-600 tracking-widest">
                I2 :{" "}
                {Number(
                  checkNumber(pvSystemData?.ac_system?.current_phase?.b)
                ).toFixed(2) ?? 0}{" "}
                A
              </span>
              <ProgressBar
                className="flex-1 space-x-2 mr-1"
                height="10px"
                completed={pvSystemData?.ac_system?.current_phase?.b ?? 0}
                maxCompleted={Number(pvSystemData?.inverter?.imax_ac)}
                customLabel={" "}
                animateOnRender
                bgColor={"#74AF4D"}
              />
            </p>
            <p className={`flex flex-col justify-between px-3 py-2 border-b `}>
              {" "}
              <span className=" mr-2 text-gray-600 tracking-widest">
                I3 :{" "}
                {Number(
                  checkNumber(pvSystemData?.ac_system?.current_phase?.c)
                ).toFixed(2) ?? 0}{" "}
                A
              </span>
              <ProgressBar
                className="flex-1 space-x-2 mr-1"
                height="10px"
                completed={pvSystemData?.ac_system?.current_phase?.c ?? 0}
                maxCompleted={Number(pvSystemData?.inverter?.imax_ac)}
                customLabel={" "}
                animateOnRender
                bgColor={"#74AF4D"}
              />
            </p>
          </div>
        </div>
        {/* ------- Power -------- */}
        <div className="">
          <div className="flex justify-between mb-4 items-center">
            <h2 className="text-xl mb-4 font-semibold !text-[#333]">
              {" "}
              AC Power{" "}
            </h2>
            <img
              className="object-contain h-[32px] w-[32px]"
              src="/images/pvsystem-Icons/PowerIcon.svg"
              alt=""
            />
          </div>
          <div className="space-y-5">
            <p className={`flex flex-col justify-between px-3 py-2 border-b `}>
              {" "}
              <span className=" mr-2 text-gray-600 tracking-widest">
                P1 : {!isNaN(ac_power_a) ? ac_power_a.toFixed(3) : 0} kW{" "}
              </span>
              <ProgressBar
                className="flex-1 space-x-2 mr-1"
                height="10px"
                completed={!isNaN(ac_power_a) ? ac_power_a.toFixed(3) : 0}
                customLabel={" "}
                maxCompleted={acPowerMaxValue}
                animateOnRender
                bgColor={"#74AF4D"}
              />
            </p>
            <p className={`flex flex-col justify-between px-3 py-2 border-b `}>
              {" "}
              <span className="text-gray-600 tracking-widest mr-2">
                P2 : {!isNaN(ac_power_b) ? ac_power_b.toFixed(3) : 0} kW
              </span>
              <ProgressBar
                className="flex-1 space-x-2 mr-1"
                height="10px"
                completed={!isNaN(ac_power_b) ? ac_power_b.toFixed(3) : 0}
                maxCompleted={acPowerMaxValue}
                customLabel={" "}
                animateOnRender
                bgColor={"#74AF4D"}
              />
            </p>
            <p className={`flex flex-col justify-between px-3 py-2 border-b `}>
              {" "}
              <span className="text-gray-600 tracking-widest mr-2">
                P3 : {!isNaN(ac_power_b) ? ac_power_c.toFixed(3) : 0} kW
              </span>
              <ProgressBar
                className="flex-1 space-x-2 mr-1"
                height="10px"
                completed={!isNaN(ac_power_b) ? ac_power_c.toFixed(3) : 0}
                customLabel={" "}
                maxCompleted={acPowerMaxValue}
                animateOnRender
                bgColor={"#74AF4D"}
              />{" "}
            </p>
          </div>
        </div>
        <div className="">
          <div className="flex justify-between mb-4 items-center">
            <h2 className="text-xl mb-4 font-semibold !text-[#333]">
              {" "}
              Frequency{" "}
            </h2>
            <img
              className="object-contain h-[32px] w-[32px]"
              src="/images/pvsystem-Icons/FrequencyIcon.svg"
              alt=""
            />
          </div>
          <p className="text-[#898989] font-semibold border-2 py-4  rounded-xl text-center border-green-500 text-xl">
            {" "}
            {checker(pvSystemData?.ac_system?.grid_frequency, "Hz")}
          </p>
        </div>
      </div>
    </div>
  );
}

function TrinaSystemCard({
  title,
  render,
}: {
  title: string;
  render: () => ReactNode;
}) {
  return (
    <div className=" ">
      <div className="space-y-3">
        <h2 className="text-[#74AF4D] text-center rounded-md border-2 border-[#74AF4D] px-7 py-2 ">
          {title}
        </h2>
        <ul className="max-w-xs list-disc list-inside text-gray-500 text-xs dark:text-gray-400 space-y-2">
          {render()}
        </ul>
      </div>
    </div>
  );
}

export default PVsystem;
